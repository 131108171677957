/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { get, truncate } from 'lodash';

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../components/elements'


const notice = (props) => {

    const edges = get(props.data, 'prismic.allGeneral_noticess.edges', []);
    const data = get(edges, '0.node');

    if (!data) {
        return ("No notices have been posted yet");
    }

    return (
        <Layout>
            <SEO meta_title={RichText.asText(data.title)} meta_description={`${truncate(RichText.asText(data.content), {
                length: 140,
                separator: ' ',
            }
            )}`} />
            <PubHero title="General Notices" />
            <Section >
                <Container>
                    <Flex flexWrap="wrap" justifyContent="center">
                        <Box width={['full', 4 / 5, 3 / 4]} sx={{
                            backgroundColor: 'wrapperbox',
                            padding: [4, 5],
                        }}>
                            <h2>{RichText.asText(data.title)}</h2>
                            <hr />
                            {RichText.render(data.content)}
                        </Box>
                    </Flex>
                </Container>
            </Section>
        </Layout>
    )
}

export default notice

export const query = graphql`
query NoticeQuery($uid: String!) {
  prismic {
    allGeneral_noticess(lang: "en-us", uid: $uid) {
    edges{
        node {
        _meta {
        uid
        }
        title
        content
        }
    }
    }
  }
}
`
